<template>
  <!-- Why the heck do to we need a component for this? -->
  <span>Logging you out...</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { signOut } from "@/services/auth.service"

import { MODULE_NAME, GetterTypes } from "@/store/AuthModule"

export default {
  name: "Logout",
  computed: {
    ...mapGetters("auth", ["user", "clientID", "hasPreGame"])
  },
  methods: {
    ...mapActions("livechat", ["end"])
  },
  async created() {
    const { clientID } = this
    const { gameID } = this.user

    if (this.hasPreGame) {
      try {
        await this.end()
      } catch (e) {
        console.error("Cannot end Expo Chat session")
      }
    }

    const isAnon = this.$store.getters[`${MODULE_NAME}/${GetterTypes.IS_ANON}`]

    if (!isAnon) {
      await signOut()
    }

    if (this.hasPreGame && clientID && gameID) {
      await this.$router.push(`/login/${clientID}${gameID}`)
    } else if (clientID) {
      await this.$router.push(`/login/${clientID}`)
    } else {
      await this.$router.push(`/login`)
    }
  }
}
</script>
